<template>
    <div>
        <h2><router-link :to="{ name: 'supplier' }">จัดการซัพพลายเออร์</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}ซัพพลายเออร์</h2>
        <v-card class="mt-4">
          <v-card-title>ประเภทช่องทาง</v-card-title>

          <v-card-text>
            <v-form v-model="isFormValid" @submit.prevent="submitForm">              
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อซัพพลายเออร์ *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.email"
                      outlined
                      label="อีเมล"                               
                      :rules="[validators.maxLengthValidator(formData.email, 250)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox 
                    v-model="formData.is_vat" 
                    label="อยู่ในระบบภาษี"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <template v-if="formData.is_vat">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.taxno"
                      outlined
                      label="เลขประจำตัวผู้เสียภาษี *"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.taxno, 13)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-textarea
                      v-model="formData.address"
                      outlined
                      label="ที่อยู่ *"                               
                      :rules="[validators.required]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.contactPoint"
                      outlined
                      label="ผู้ติดต่อ *"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.contactPoint, 50)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.tel"
                      outlined
                      label="เบอร์ *"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.tel, 20)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>                
              </template>
            </v-form>            
          </v-card-text>          

          <v-card-actions>              
            <v-btn
              color="primary"               
              large          
              :disabled="!isFormValid"
              :loading="sendingData"
              @click="submitForm"
              v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
            >
              {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
            </v-btn>

            <v-btn
              color="secondary"               
              outlined                 
              large
              :disabled="sendingData"
              @click="$router.go(-1)"
            >
              กลับ
            </v-btn>                
          </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { maxLengthValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPost, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        name: null,
        is_vat: false,
        contactPoint: null,
        taxno: null,
        address: null,
        tel: null,
        email: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  watch: {
    'formData.parent': function (value) {
      if (!value) return (this.formData.parent_id = null)

      this.formData.parent_id = value.id
    },
  },
  async created() {
    const supplier_id = this.$route.params.supplier_id

    if (supplier_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Product'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { name, is_vat, contactPoint, taxno, address, tel, email } = await asyncGet(
        '/product/supplier/' + supplier_id,
      )

      this.formData = {
        name: name,
        is_vat: !!is_vat,
        contactPoint: contactPoint,
        taxno: taxno,
        address: address,
        tel: tel,
        email: email,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      if (!this.isFormValid) return

      this.sendingData = true
      try {
        const supplier_id = this.$route.params.supplier_id
        if (supplier_id == 'create') await asyncPost('/product/supplier', this.formData)
        else {
          await asyncUpdate('/product/supplier/' + supplier_id, this.formData)
        }

        this.$router.push({ name: 'supplier' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>